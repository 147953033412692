<template>
<div>
  <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card :loading="loadingDelete">
            <v-card-title class="headline">Excluir este Anúncio {{$store.state.ads.editedAds._id}}?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1"  text @click="dialogDelete = false"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" :loading="loadingDelete" text @click="deleteItemConfirm"
                >Sim</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
  <v-data-table
    :headers="headers"
    :items="$store.state.ads.data"
    sort-by="startDate"
    class="elevation-1"
    :loading="loading"
    :search="$store.state.search"
    loading-text="Carregando..."
    sort-desc
    show-select
    :single-select="false"
    v-model="selected"
    item-key="_id"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Anuncios</v-toolbar-title>

        <v-divider class="mx-4" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <actions :showActiveAll="selected.length" :showSave="selected.length" @update="update" @switch="data => status = data" @delete="deleteItems"></actions>
        <tooltip></tooltip>

        
      </v-toolbar>
    </template>

    <template #item.name="{ item }">
      <span class="link" @click="editItem(item)">{{item.name}}</span>
    </template>

    <template v-slot:item.status="{ item }">
      <v-avatar
        :color="item.status ? 'green' : 'red'"
        size="20"
        :title="item.status ? 'Ativo' : 'Inativo'"
      >
      </v-avatar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>

    <template v-slot:item.link="{ item }">
        <span
          style="max-width: 150px"
          :title="item.link"
          class="d-inline-block text-truncate"
          ><a :href="item.link" target="_blank">{{ item.link }}</a></span
        >
      </template>

      <template v-slot:item.qrcode="{ item }">
        <span
          style="max-width: 150px"
          :title="item.imqrcodeg"
          class="d-inline-block text-truncate"
          ><a :href="item.qrcode" target="_blank">{{ item.qrcode }}</a></span
        >
      </template>

      <template  v-slot:item.startDate="{ item }" >
        <start-date :item="item" />
      </template>

      <template  v-slot:item.endDate="{ item }">
        <end-date :item="item" />
      </template>

    <template v-slot:no-data=""> Nenhum anúncio cadastrado </template>

  </v-data-table>
  </div>
</template>

<script>
import Tooltip from "./tooltip";
import lodash from "lodash";
import {getAds, deleteAds, updateAds} from '../../services/ads'
import actions from './actions.vue'
import startDate from './startDate.vue'
import endDate from './endDate.vue'
export default {
  components: {
    Tooltip,
    actions,
    startDate,
    endDate
  },
  data: () => ({
    status: true,
    selected:[],
    loading: false,
    loadingDelete:false,
    dialogDelete: false,
    headers: [
      {
        text: "Nome",
        align: "start",
        value: "name",
      },
      { text: "Status", value: "status" },
      { text: "Total em Playlists", value: "total" },
      { text: "Mídia", value: "link" },
      { text: "QrCode", value: "qrcode" },
      { text: "Data de Início", value: "startDate" },
      { text: "Data de finalização", value: "endDate" },
      { text: "Usuário", value: "user.name" },
      { text: "Actions", value: "actions", sortable: false },
    ]
  }),

  methods: {
    lodash() {
      return lodash;
    },

    async update(){
      await updateAds(this.selected.map(item => ({...item, status: this.status})))
        .then(() => {
          this.selected.forEach(item => {
            item.status = this.status
          });
          this.$store.commit('success', 'Anuncios alterados com sucesso')
        })
        .catch(err => this.$store.commit('failed', 'Falha ao tentar alterar em lote: \r\n', err.stack))
      this.selected = []
    },

    deleteItems(){
      this.dialogDelete = true;
    },

    editItem(item) {
        this.$store.commit('editAds', item)
        this.$nextTick(()=>this.$router.push('/advertisements/edit/'+item._id))
        
    },

    deleteItem(item) {
      this.$store.commit('prepareAdsToDelete', item) 
      this.dialogDelete = true;
    },

    async deleteMultiple(){
      for(const ads of this.selected){
        await deleteAds(ads._id)
        const index = lodash.findIndex(this.$store.state.ads.data, {_id: ads._id})
       this.$store.state.ads.data.splice(index, 1)
      }
      this.loadingDelete = false
      this.dialogDelete = false
    },

    deleteItemConfirm() {
      this.loadingDelete = true
      
      if(this.selected.length){
        return this.deleteMultiple()
      }
     deleteAds(this.$store.state.ads.editedAds._id).then(()=>{
       this.$store.commit('success', 'Ads deletado com sucesso')
       this.loadingDelete = false
       this.dialogDelete = false;
       const index = lodash.findIndex(this.$store.state.ads.data, {_id: this.$store.state.ads.editedAds._id})
       this.$store.state.ads.data.splice(index, 1)
     })
     .catch(err=>{
       this.$store.commit('failed', 'Falha ao deletar este ads: '+err)
       this.loadingDelete = true
     })
      
    },

  },
  async mounted(){
    this.$store.state.searched = true;
    this.loading = true
    await getAds().catch(()=>{})
    this.loading = false
  },
  beforeDestroy(){
    this.$store.state.searched = false;
  }
};
</script>
<style scoped>
.link{
  cursor:pointer
}
</style>