var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',{attrs:{"loading":_vm.loadingDelete}},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Excluir este Anúncio "+_vm._s(_vm.$store.state.ads.editedAds._id)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","loading":_vm.loadingDelete,"text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Sim")]),_c('v-spacer')],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.$store.state.ads.data,"sort-by":"startDate","loading":_vm.loading,"search":_vm.$store.state.search,"loading-text":"Carregando...","sort-desc":"","show-select":"","single-select":false,"item-key":"_id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Anuncios")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('actions',{attrs:{"showActiveAll":_vm.selected.length,"showSave":_vm.selected.length},on:{"update":_vm.update,"switch":function (data) { return _vm.status = data; },"delete":_vm.deleteItems}}),_c('tooltip')],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.status ? 'green' : 'red',"size":"20","title":item.status ? 'Ativo' : 'Inativo'}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"},attrs:{"title":item.link}},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item.link))])])]}},{key:"item.qrcode",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"},attrs:{"title":item.imqrcodeg}},[_c('a',{attrs:{"href":item.qrcode,"target":"_blank"}},[_vm._v(_vm._s(item.qrcode))])])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('start-date',{attrs:{"item":item}})]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('end-date',{attrs:{"item":item}})]}},{key:"no-data",fn:function(){return [_vm._v(" Nenhum anúncio cadastrado ")]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }