<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }" >
      <v-btn
        class="mx-2"
        color="primary"
        fab
        small
        dark
        v-bind="attrs"
        v-on="on"
        @click="openNewNews"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <span>Novo Totem</span>
  </v-tooltip>
</template>

<script>
export default {
  methods:{
    openNewNews(){
      this.$router.push('/advertisements/add')
    }
  }
};
</script>

<style lang="scss" scoped>
</style>