<template>
  <div>
    <table >
      <tr>
          <td v-if="showActiveAll" style="padding: 10px">
          <v-switch
            style="margin-top: 30px"
            :label="`Status: ${status ? 'Ativo' : 'Inativo'}`"
            v-model="status"
            @click="$emit('switch', status)"
          ></v-switch>
        </td>
        <td v-if="showActiveAll || showSave">
          <v-btn tile color="success" @click="$emit('update')">
            <v-icon left> mdi-content-save </v-icon>
            Salvar
          </v-btn>
        </td>
        <td>
          <v-btn v-if="showActiveAll" tile title="Remover Selecionados" color="error" @click="$emit('delete')">
            <v-icon left> mdi-trash-can-outline </v-icon>
            Excluir
          </v-btn>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["showActiveAll", "showSave"],
  data: () => ({
    status: true,
  }),
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>