import axios from 'axios'
import baseUrl from './baseUrl'
import store from '../store'
const errors = err => {
  console.error(err.response ? err.response.data.stack : err.stack)
  return Promise.reject(err.response ? err.response.data.message:'Houve uma falha na comunição, verifique sua internet e tente novamente')
}
const headers = {token: localStorage.getItem('token')}
const advertisements = axios.create({ baseURL: baseUrl.tnAdmin, headers })

export const getAds = () => advertisements.get('/advertisements').then(({data}) => store.state.ads.data = data)
.catch(errors)

export const createAds = () => advertisements.post('/advertisements', store.state.ads.newAds).then(({data}) => data).catch(errors)

export const updateAds = (adss) => advertisements.put('/advertisements/', adss)
.then(({data}) => data)
.catch(errors)

export const getAdsById = (id) => advertisements.get('/advertisements/'+id)
.then(({data}) => {
  data.startDate = new Date(data.startDate).toLocaleDateString()
  data.endDate = new Date(data.endDate).toLocaleDateString()
  store.state.ads.editedAds = data
})
.catch(errors)

export const deleteAds = (id) => advertisements.delete('/advertisements/'+id).then(({data})=>data).catch(errors)