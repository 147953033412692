<template>
    <div>
        <input :disabled="disabled" type="text" style="text-align:center" v-mask="'##/##/####'" v-model="startDate" @keyup.enter="save" @blur="save">
    </div>
</template>

<script>
import { updateAds } from '../../services/ads'
    export default {
        props: ["item"],
        data: ()=>({
            startDate: '',
            disabled: false
        }),
        mounted(){
            this.startDate = new Date(this.item.startDate).toLocaleDateString()
        },
        methods:{
            async save(){
                if(this.startDate.length != 10){
                    this.$store.commit('failed', 'Data de início para o anúncio não foi alterada porque você digitou um formato incorreto, formato aceito: '+new Date(this.item.startDate).toLocaleDateString()+' !')
                    return this.startDate = new Date(this.item.startDate).toLocaleDateString()
                }
                if(this.startDate != new Date(this.item.startDate).toLocaleDateString()){
                    this.disabled = true
                   this.$nextTick(()=> this.disabled = false)
                    const date = new Date(this.startDate.split('/').reverse().join('-')+'T03:00:00.0000Z').toJSON()
                    if(!date){
                        return this.$store.commit('failed', 'Valor passado incorreto')
                    }
                    this.item.startDate = date
                    this.$store.commit("setLoading", true)
                    await updateAds([this.item]).then(()=>this.$store.commit('success', 'Alterado com sucesso')).catch(err => this.$store.commit('failed', err))
                    this.$store.commit("setLoading", false)
                }
            }
        }
    }
</script>
